/* eslint-disable no-unused-vars */

import 'lobibox/dist/js/notifications.js';

window.Lobibox.notify.DEFAULTS = $.extend({}, window.Lobibox.notify.DEFAULTS, {
  size: 'mini',
  iconSource: 'fontAwesome',
  delayIndicator: false,
  rounded: true,
  sound: false,
  position: 'top right',
  messageHeight: false
});

window.Lobibox.notify.OPTIONS = $.extend({}, window.Lobibox.notify.OPTIONS, {
  icons: {
    fontAwesome: {
      error: 'fa fa-exclamation',
      info: 'fa fa-exclamation'
    }
  }
});

function notify(type, message, options) {
  const DEFAULT_NOTIFICATION_DELAY = 5000;

  return window.Lobibox.notify(type, {
    msg: message,
    delay: type === 'error' ? false : DEFAULT_NOTIFICATION_DELAY,
    ...options
  });
}

function notifyInfo(message) {
  return notify('info', message);
}

function notifySuccess(message, options) {
  return notify('success', message, options);
}

function notifyError(message) {
  return notify('error', message);
}

function showFlashMessage() {
  const $flashMessage = $('#flash_message');
  const type = $flashMessage.data('type');
  const message = $flashMessage.data('message');

  if (type && message) {
    notify(type, message);
  }
}

function createErrorNotification() {
  let notification = null;

  const showMessage = (msg) => {
    if (notification && notification.$el.hasClass(notification.$options.showClass)) {
      notification.$el.find('.lobibox-notify-msg').text(msg);
    } else {
      notification = notifyError(msg);
    }
  };

  const remove = () => {
    if (notification) {
      notification.remove();
    }
  };

  return { showMessage, remove };
}

window.notify = notify;
window.notifyInfo = notifyInfo;
window.notifySuccess = notifySuccess;
window.notifyError = notifyError;
window.showFlashMessage = showFlashMessage;
window.createErrorNotification = createErrorNotification;
